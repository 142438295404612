import logo from "../../../assets/icons/logo.png";
import styles from "./zk_logo.module.css";

const ZKLogo = () => {
  return (
    <div className={styles.logo}>
      <img src={logo} alt="logo" className={styles.appLogo} />
      <h1 className={styles.companyName}>zkstarter</h1>
    </div>
  );
};

export default ZKLogo;
