import React from "react";
import { Routes, Route } from "react-router-dom";
// import HomePage from "../features/home/home.jsx";
import ComingSoon from "../features/coming_soon/coming_soon.jsx";

const AppRouter = () => {
  return (
    <Routes>
      <Route exact path="/" element={<ComingSoon />}></Route>
      {/* <Route exact path="/" element={<HomePage />}></Route>
      <Route exact path="/coming-soon" element={<ComingSoon />}></Route> */}
    </Routes>
  );
};

export default AppRouter;
