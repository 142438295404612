import styles from "./coming_soon.module.css";
import { Cloud, StarySky } from "./components";
import { ZKLogo } from "../../core/components";

const ComingSoon = () => {
  return (
    <>
      <StarySky className={styles.canvas} />
      <div className={styles.container}>
        <Cloud scale={0.4} top={45} animationDuration={35} />
        <Cloud scale={0.25} top={15} animationDuration={65} />
        <Cloud scale={0.3} top={27} animationDuration={40} />
        <h2 className={styles.info_one}>Something great is on the way</h2>
        <h1 className={styles.header}>COMING SOON</h1>
        <ZKLogo />
      </div>
    </>
  );
};

export default ComingSoon;
