import styles from "./cloud.module.css";

const Cloud = ({ scale = 1, top, animationDuration = 15 }) => {
  return (
    <div
      className={styles.cloud}
      style={{
        transform: `scale(${scale})`,
        top: `${top}vh`,
        animationDuration: `${animationDuration}s`,
      }}
    ></div>
  );
};

export default Cloud;
